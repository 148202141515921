footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        flex-direction: column;
        .copyright {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-1;
            margin-bottom: 20px;
        }
        .mediaweb {
            a {
                .text {
                    p {
                        font-size: 14px !important;
                        color: $color-1;
                    }
                }
            }
        }
        .icon-reseaux {
            position: absolute;
            right: 5%;
        }
        @media screen and (max-width: 1100px) {
            display: flex;
            flex-direction: column-reverse;
            .copyright {
                margin: 15px 0 0 0;
            }
            .icon-reseaux {
                position: initial;
                padding-bottom: 35px;
            }
            @media screen and (max-width: $size-sm-max) {
                .icon.mr5 {
                    margin-right: 2rem;
                }
            }
        }
    }
    @media screen and (max-width: 1100px) {
        min-height: 230px;
        @media screen and (max-width: $size-xs-max) {
            min-height: 255px;
        }
    }
}
