// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
//@import 'utilities/wrapper';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}


a.link-word {
    margin-top: 35px;
    h5 {
        font-weight: bold;
        font-size: $font-size-20;
        color: $color-1;
        text-transform: uppercase;
        letter-spacing: 0.8px;
    }
}

.content-full {
    display: none;
    &.full {
        padding: 0 5%;
        &.one {
            margin-top: -100px;
            @media screen and (max-width: 1200px) {
                margin: -75px auto 0 auto;
            }
        }
        &.small {
            margin-top: -50px;
            @media screen and (max-width: 1200px) {
                margin: -75px auto 75px auto;
                @media screen and (max-width: $size-xs-max) {
                    margin: -20px auto 75px auto;
                    &.second {
                        margin: -75px auto 75px auto;
                    }
                }
            }
        }
        &.background {
            background: #FEF6EA;
            padding: 75px 5%;
            margin-top: -85px;
            margin-right: 5%;
            margin-bottom: 100px;
            p {
                color: $color-1;
            }
            @media screen and (max-width: 1200px) {
                margin: -85px auto 100px auto;
                padding: 50px !important;
                width: 67.5%;
                @media screen and (max-width: $size-sm-max) {
                    width: 90%;
                    margin: -85px 5% 100px 5%;
                    @media screen and (max-width: $size-xs-max) {
                        padding: 50px 25px !important;
                    }
                }
            }
        }
    }
    p {
        //font-size: $font-size-20;
    }
    @media screen and (max-width: 1375px) {
        p {
            font-size: 16px;
        }
        @media screen and (max-width: 1200px) {
            width: 75%;
            margin: 0 auto;
            padding: 0 4% !important;
            @media screen and (max-width: $size-sm-max) {
                width: 100%;
                padding: 0 5% !important;
            }
        }
    }
}

a.btn-plus, a.btn-moins {
    cursor: pointer;
    display: block;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-loi25 {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 101;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 70px;
        left: initial;
        right: 10px;
    }
}

#section-intro {
    padding: 65px 5%;
    background: #FEFEFE;
    margin: -50px 5% 0 5%;
    position: relative;
    z-index: 10;
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: $font-size-40;
        color: $color-3;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    p {
        //font-size: $font-size-20;
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 65px 0; 
    }
}

#section-images {
    padding: 50px 5%;
    @media screen and (max-width: 1160px) {
        flex-wrap: wrap;
        justify-content: center;
        .image {
            width: 31%;
            &.mr4 {
                margin-bottom: 1rem;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .image {
                width: 47%;
            }
            @media screen and (max-width: $size-xs-max) {
                .image {
                    width: 100%;
                    .img-responsive {
                        margin: auto;
                    }
                    &.mr4 {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

#section-bloc {
    padding: 100px 5%;
    .section-bloc {
        padding-bottom: 50px;
        .bloc {
            width: 32%;
            box-shadow: 8px 16px 32px #0000001A;
            border-radius: 16px;
            padding: 50px 50px 50px 25px;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-3;
            }
            p {
                font-weight: 500;
            }
            .border {
                background: $color-1;
                height: 2px;
                margin: 50px 0;
            }
        }
    }
    @media screen and (max-width: 1615px) {
        .section-bloc {
            .bloc {
                h3 {
                    font-size: 1.3rem;
                }
            }
        }
        @media screen and (max-width: 1460px) {
            .section-bloc {
                .bloc {
                    width: 35%;
                    padding: 50px 25px;
                    h3 {
                        font-size: 1.2rem;
                    }
                }
            }
            @media screen and (max-width: 1150px) {
                .section-bloc {
                    flex-wrap: wrap;
                    justify-content: center;
                    .bloc {
                        width: 49%;
                        margin-bottom: 0.5rem;
                        &.mx2 {
                            margin-right: 0;
                        }
                        .border {
                            margin: 35px 0;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 50px 5% 75px 5%;
                    .section-bloc {
                        flex-wrap: wrap;
                        justify-content: center;
                        .bloc {
                            width: 100%;
                            margin-bottom: 1rem;
                            h3 {
                                font-size: 1.5rem;
                            }
                            .border {
                                margin: 20px 0;
                            }
                        }
                    }
                } 
            }
        }
    }
}

#section-text-image {
    padding: 0 5% 0 0;
    .text-side {
        background: #FEF6EA;
        width: 60%;
        z-index: -1;
        padding: 0 10% 0 5%;
        margin-right: -115px;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-3;
            line-height: 1;
            padding-bottom: 50px;
        }
    }
    .image-side {
        width: 47%;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            h3 {
                font-size: 2.5rem;
            }
        }
        @media screen and (max-width: 1500px) {
            .text-side {
                h3 {
                    font-size: 2.2rem;
                    padding-bottom: 25px;
                }
            }
            @media screen and (max-width: 1375px) {
                .text-side {
                    padding: 0 11% 0 5%;
                    p {
                        font-size: 16px;
                    }
                }   
                @media screen and (max-width: $size-md-max) {
                    .text-side {
                        width: 65%;
                        h3 {
                            font-size: 2rem;
                        }
                    }
                    .image-side {
                        width: 45%;
                    }
                    @media screen and (max-width: 1200px) {
                        flex-direction: column-reverse;
                        padding: 0 5%;
                        .text-side {
                            width: 75%;
                            padding: 225px 50px 75px 50px;
                            margin: -150px auto 0 auto;
                        }
                        .image-side {
                            width: 75%;
                            margin: auto;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .text-side, .image-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .text-side {
                                    padding: 200px 25px 75px 25px;
                                    h3 {
                                        font-size: 2.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-image-text {
    padding: 100px 5%;
    .image-side {
        width: 47%;
        margin-right: 30px;
    }
    .text-side {
        width: 53%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-3;
            padding-bottom: 50px;
        }
        p {
            //font-size: $font-size-20;
        }
    }
    @media screen and (max-width: 1550px) {
        .text-side h3 {
            padding-bottom: 25px;
        }
        @media screen and (max-width: 1375px) {
            .text-side p {
                font-size: 16px;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .image-side {
                    width: 75%;
                    margin: 0 0 50px 0;
                }
                .text-side {
                    width: 75%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-bloc.images-top {
    padding: 75px 5% 100px 5%;
    h2 {
        font-weight: bold;
        font-size: $font-size-40;
        text-align: center;
        color: $color-3;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .bloc {
        padding: 0;
        .contenu {
            padding: 40px 25px;
            .border {
                margin: 25px 0 40px 0;
            }
        }
    }
    @media screen and (max-width: $size-sm-max) {
        padding: 75px 5%;
        .bloc {
            width: 100%;                  
        }
    }
}

#section-equipe {
    background: $color-1;
    margin: 0 5% 100px 5%;
    padding: 80px;
    h4 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #FEF6EA;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .bloc {
        h5 {
            font-weight: bold;
            font-size: $font-size-20;
            color: #FEF6EA;
            padding-bottom: 30px;
        }
        p {
            //font-size: $font-size-20;
            color: #FEF6EA;
            line-height: 1.1;
        }
    }
    @media screen and (max-width: 1715px) {
        padding: 80px 50px;
        @media screen and (max-width: 1440px) {
            .section-nom .bloc.mx16 {
                margin-left: 3rem;
                margin-right: 3rem;
            }
            @media screen and (max-width: 1200px) {
                .section-nom {
                    flex-direction: column;
                    .bloc.mx16 {
                        margin: 50px 0;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 75px 25px;
                }
            }
        }
    }
}

#section-title-logo {
    padding: 0 5% 100px 5%;
    h4 {
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-3;
        text-transform: uppercase;
        border-bottom: 1px solid $color-1;
        padding-bottom: 20px;
        margin-bottom: 60px;
    }
    @media screen and (max-width: $size-xs-max) {
        .section-logo {
            flex-wrap: wrap;
            .logo {
                width: 50%;
                padding: 10px;
                margin: 0 auto;
                .img-responsive {
                    margin: auto;
                }
            }
        }
    }
}

/*******************************************************************************
* HISTOIRE
*******************************************************************************/

#section-image-text.reverse {
    padding: 50px 5% 100px 5%;
    .image-side {
        margin: 0 0 0 50px;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        .image-side {
            margin: 0 0 50px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 50px 5%;
        }
    }
}

#section-text-background {
    padding: 50px 5% 100px 0;
    .text-side {
        background: #FEF6EA;
        width: 50%;
        padding: 50px 85px 50px 5%;
        margin-right: 15px;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-1;
            padding-bottom: 45px;
        }
        p {
            //font-size: $font-size-20;
            color: $color-1;
        }
    }
    .image-side {
        width: 50%;
        background: url("../images/histoire_section05_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            h3 {
                padding-bottom: 25px;
            }
        }
        @media screen and (max-width: 1375px) {
            .text-side {
                p {
                    font-size: 16px;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                padding: 0 5% 100px 5%;
                .image-side {
                    width: 75%;
                    margin: 0 auto 15px auto;
                }
                .text-side {
                    width: 75%;
                    padding: 50px;
                    margin: auto;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-side {
                            background: url("../images/histoire_section05_img01_m.jpg") no-repeat;
                            background-size: cover;
                        }
                        .text-side {
                            padding: 50px 25px;
                        }
                    }
                }
            }
        }
    }
    &.collection {
        .image-side {
            background: url("../images/collection_section02_img01.jpg") no-repeat;
            background-size: cover;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/collection_section02_img01_m.jpg") no-repeat;
                background-size: cover;
            }            
        }
    }
    &.services {
        .image-side {
            background: url("../images/services-educatifs_section03_img01.jpg") no-repeat;
            background-size: cover;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/services-educatifs_section03_img01_m.jpg") no-repeat;
                background-size: cover;
            }            
        }
    }
    &.services.second {
        .image-side {
            background: url("../images/services-services-educatifs_section05_img01.jpg") no-repeat;
            background-size: cover;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/services-services-educatifs_section05_img01_m.jpg") no-repeat;
                background-size: cover;
            }            
        }
    }
}

/*******************************************************************************
* ARCHÉOLOGIE
*******************************************************************************/

#section-text {
    background: #7A45010B;
    padding: 75px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-40;
        color: $color-3;
        padding-bottom: 80px;
    }
    @media screen and (max-width: 1600px) {
        h3 {
            font-size: 1.8rem;
            padding-bottom: 50px;
        }
        @media screen and (max-width: $size-md-max) {
            h3 {
                padding-bottom: 35px;
            }
        }
    }
}

#section-images.archeologique {
    padding: 125px 5% 50px 5%;
    .image {
        width: 25%;
        .img-responsive {
            width: 100%;
        }
        .section-text {
            padding-top: 15px;
            .border {
                height: 2px;
                width: 53px;
                margin-right: 15px;
                background: $color-1;
            }
            .text {
                width: 85%;
                p {
                    font-size: 16px;
                }
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        flex-wrap: wrap;
        padding: 100px 5% 0 5%;
        .image {
            width: 48%;
            margin: 0 0 50px 0;
            &:nth-child(odd) {
                margin: 0 15px 50px 0;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            .image {
                width: 100%;
                margin: 0 0 50px 0; 
                &:nth-child(odd) {
                    margin: 0 0 50px 0;
                }
            }   
        }
    }
}

#section-intro.archeologique {
    padding: 150px 5% 100px 5%;
    margin: 0;
    position: initial;
    h2 {
        font-size: $font-size-60;
    }
    @media screen and (max-width: 1600px) {
        h2 {
            font-size: 2.5rem;
        }
        @media screen and (max-width: $size-md-max) {
            padding: 100px 5%;
            h2 {
                font-size: 2.3rem;
            }
            @media screen and (max-width: $size-xs-max) {
                h2 {
                    padding-bottom: 35px;
                }
                @media screen and (max-width: 375px) {
                    h2 {
                        font-size: 2.15rem;
                    }
                }
            }
        }
    }
}

#section-video {
    padding: 0 5% 100px 5%;
    @media screen and (max-width: 1650px) {
        iframe {
            height: 700px;
        }
        @media screen and (max-width: $size-md-max) {
            iframe {
                height: 600px;
                width: 1000px;
            }
            @media screen and (max-width: 1024px) {
                iframe {
                    height: 500px;
                    width: 850px;
                }
                @media screen and (max-width: $size-sm-max) {
                    iframe {
                        height: 450px;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        iframe {
                            height: 350px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COLLECTION
*******************************************************************************/

#section-image-text.collection {
    &.collection {
         padding: 25px 5% 75px 5%;
        .image-side {
            width: 45%;
            margin-right: 50px;
        }
        .text-side {
            width: 55%;
        }
        @media screen and (max-width: 1200px) {
            .image-side {
                width: 75%;
                margin-right: 0;
            }
            .text-side {
                width: 75%;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
            }
        }
    }
    &.reverse.collection {
        padding: 75px 5% 100px 5%;
        @media screen and (max-width: $size-xs-max) {
            padding: 50px 5% 100px 5%;
        }
    }
}

/*******************************************************************************
* SERVICE ÉDUCATIFS
*******************************************************************************/

#section-intro.services {
    p {
        font-weight: 600;
        font-style: italic;
    }
}

#section-image-text.service {
    .image-side {
        margin-right: 50px;
        @media screen and (max-width: 1200px) {
            margin: 0 0 50px 0;
        }
    }
}

#section-text-background.services {
    padding: 0 5%;
}

#section-background {
    background: $color-1;
    padding: 30px 5% 30px 35px;
    margin: 0 0 75px 5%;
    .image-side {
        width: 35%;
        margin-right: 75px;
    }
    .text-side {
        width: 65%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-2;
            padding-bottom: 50px;
        }
        p {
            color: $color-2;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 50px;
        margin: 0 5% 75px 5%;
        .image-side {
            width: 50%;
            margin: 0 0 50px 0;
        }
        .text-side {
            width: 100%;
            h3 {
                padding-bottom: 35px;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side {
                width: 75%;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 50px 25px;
                .image-side {
                    width: 100%;
                }
            }
        }
    }
}

/*******************************************************************************
* LES AMI(ES)
*******************************************************************************/

#section-amis {
    padding: 80px 5%;
    .bloc {
        background: #7A4501A8;
        padding: 90px 25px;
        width: 50%;
        h3 {
            font-weight: bold;
            font-size: $font-size-42;
            color: $color-2;
            padding-bottom: 50px;
        }
        p {
            color: $color-2;
        }
        a.btn-pdf {
            background: $color-1;
            width: 135px;
            height: 85px;
            margin-top: 25px;
            border-radius: 6px;
            transition: 0.3s;
            h5 {
                font-weight: bold;
                font-size: $font-size-20;
                color: $color-2;
                letter-spacing: 0.8px;
            }
            &:hover {
                background: $color-3;
                transition: 0.3s;
            }
        }
        &.left-side {
            margin-right: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .bloc {
            width: 85%;
            padding: 75px 25px;
            margin: 0 auto;
            a.btn-pdf {
                margin-top: 50px;
            }
            &.left-side {
                margin: 0 auto 15px auto;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .bloc {
                width: 100%;
            }
        }
    }
}

/*******************************************************************************
* NOUS JOINDRE
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    .form-info {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 25px;
        background: $color-white;
        box-shadow: 0px 0px 6px #00000017;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-3;
            padding-bottom: 25px;
        }
    }
    .text-side {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: $color-3;
        .intro {
            h2 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #FEF6EA;
                padding-bottom: 5px;
            }
            h4 {
                font-weight: normal;
                font-size: $font-size-20;
                color: #FEF6EA;
            }
        }
        
        .section-info {
            .text strong {
                font-weight: bold;
                color: #FEF6EA;
            }
            .icon {
                width: 12%;
            }
            .text {
                width: 100%;
                h3 {
                    font-weight: bold;
                    font-size: $font-size-24;
                    color: #FEF6EA;
                    text-transform: initial;
                    padding-bottom: 8px;
                }
                p, a {
                    font-weight: normal;
                    font-size: 18px;
                    color: #FEF6EA;
                }
                .section-saison {
                    padding: 45px 0 25px 0;
                    h4 {
                        font-weight: bold;
                        font-size: 18px;
                        color: #FEF6EA;
                        padding-bottom: 5px;
                        text-transform: uppercase;
                    }
                    h5 {
                        font-weight: 500;
                        font-size: 18px;
                        color: #FEF6EA;
                    }
                }
                .jours-heures {
                    padding-top: 10px;
                    h5, h4 {
                        font-weight: 500;
                        font-size: 18px;
                        color: #FEF6EA;
                        padding-bottom: 0;
                        line-height: 3.5;
                    }
                    h4 {
                        font-weight: bold;
                    }
                    .jour.mr8 {
                        width: 55%;
                    }
                }
                .section-ouvert {
                    padding-top: 25px;
                    h5 {
                        font-weight: 500;
                        font-size: 18px;
                        color: #FEF6EA;
                    }
                }
                .section-bouton {
                    padding-top: 65px;
                    a.btn-link {
                        width: 195px;
                        height: 85px;
                        border-radius: 6px;
                        background: $color-1;
                        h5 {
                            font-weight: bold;
                            font-size: $font-size-20;
                            color: #FEF6EA;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                        }
                        &.btn-depliant {
                            margin-right: 15px;
                        }
                        &.btn-carte {
                            width: 165px;
                            background: #fff;
                            h5 {
                                color: $color-1;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1650px) {
        .text-side {
            padding: 65px 35px 35px 35px;
        }
        @media screen and (max-width: 1570px) {
            .section-form {
                padding: 65px 65px 50px 65px;
            }
            .text-side .section-info .text .section-bouton a.btn-link {
                width: 150px;
                height: 75px;
            }
            @media screen and (max-width: 1430px) {
                .text-side {
                    padding: 65px 25px;
                }
                @media screen and (max-width: 1370px) {
                    .text-side {
                        .section-info {
                            .text {
                                .jours-heures {
                                    h5, h4 {
                                        font-size: 16px;
                                    }
                                    .jour {
                                        width: 58%;
                                    }
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-md-max) {
                        padding: 0 5% 50px 5%;
                        .form-info {
                            flex-direction: column-reverse;
                            margin-top: -150px !important;
                            .text-side {
                                width: 85%;
                                margin: auto;
                                padding: 65px 35px 75px 35px;
                                .section-info {
                                    .text {
                                        .jours-heures {
                                            h5, h4 {
                                                font-size: 18px;
                                            }
                                            .jour {
                                                width: 30%;
                                            }
                                        }
                                    }
                                }
                            }
                            .section-form {
                                width: 85%;
                                margin: 35px auto 0 auto;
                            }
                        }
                        @media screen and (max-width: 1024px) {
                            .form-info {
                                .text-side {
                                    width: 100%;
                                    padding: 65px 45px;
                                }
                                .section-form {
                                    width: 100%;
                                }
                            }
                            @media screen and (max-width: $size-sm-max) {
                                .form-info {
                                    margin-top: -95px !important;
                                    .section-form {
                                        padding: 65px 50px 50px 50px;
                                    }
                                    .text-side .section-info .text .jours-heures .jour {
                                        width: 50%;
                                    }
                                }
                                @media screen and (max-width: $size-xs-max) {
                                    padding: 0 5%;
                                    .form-info {
                                        margin-top: -130px !important;
                                        .text-side {
                                            width: 100%;
                                            padding: 50px 25px;
                                            .intro {
                                                h2 {
                                                    font-size: 2.15rem;
                                                }
                                                h4 {
                                                    font-size: 1.2rem;
                                                }
                                            }
                                            .icon {
                                                display: none;
                                            }
                                            .section-info {
                                                .text {
                                                    .jours-heures {
                                                        h5, h4 {
                                                            font-size: 16px;
                                                        }
                                                        .jour {
                                                            width: 61%;
                                                        }
                                                    }
                                                    .section-bouton {
                                                        a.btn-link h5 {
                                                            font-size: 16px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .section-form {
                                            padding: 50px 25px;
                                            .form-group.col-xs-12.col-sm-6.pl0 {
                                                padding: 0;
                                            }
                                            h3 {
                                                text-align: center;
                                            }
                                        }
                                    }
                                    @media screen and (max-width: 375px) {
                                        .form-info {
                                            .text-side {
                                                .section-info {
                                                    .text {
                                                        .jours-heures {
                                                            .jour {
                                                                width: 55%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        @media screen and (max-width: 360px) {
                                            .form-info {
                                                .text-side {
                                                    .section-info {
                                                        .text {
                                                            .jours-heures {
                                                                h5, h4 {
                                                                    line-height: 1;
                                                                    padding-bottom: 35px;
                                                                }  
                                                                .jour {
                                                                    width: 50%;
                                                                }
                                                            }
                                                            .section-bouton {
                                                                flex-direction: column;
                                                                align-items: center;
                                                                a.btn-link {
                                                                    &.btn-depliant {
                                                                        width: 165px;
                                                                        margin: 0 0 15px 0;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 0 5% 75px 5%;
    
    @media screen and (max-width: 1500px) {
        #map-canvas {
            height: 650px !important
        }
        @media screen and (max-width: 1175px) {
            #map-canvas {
                height: 500px !important
            }
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-3;
    }
}



/*******************************************************************************
* COMMEMORATION 250
*******************************************************************************/
#section-image-text{
    p.bold{
        font-weight: 700;
    }
}
#section-intro.commemoration{
    margin: 0;
}
#section-text-background{
    &.festivites{
        padding: 50px 0 100px 5%;
        .image-side {
            background: url("../images/commemoration_section02_img01.png") no-repeat;
            background-size: cover;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/commemoration_section02_img01_m.png") no-repeat;
                background-size: cover;
            }            
        }
    }
    &.conferences{
        .image-side {
            background: url("../images/commemoration_section07_img01.jpg") no-repeat;
            background-size: cover;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/commemoration_section07_img01_m.jpg") no-repeat;
                background-size: cover;
            }            
        }
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
    

}
