$input_height: 70px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: 95px;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $color-white;
        border: 1px solid $color-3;
        border-radius: 3px;
        box-sizing: border-box;
        @media screen and (max-width: $size-xs-max){
            height: 75px;
        }
    }
    input::placeholder, textarea::placeholder {
        font-weight: normal !important;
        font-size: 16px !important;
        color: #063448 !important;
        text-transform: uppercase;
        opacity: 0.44;
        @media screen and (max-width: $size-xs-max) {
            font-size: 14px !important;
        }
    }
    textarea::placeholder {
        padding: 35px 5px !important;
        font-size: 18px !important;
        @media screen and (max-width: $size-xs-max) {
            font-size: 14px !important;
        }
    }
    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .clear-both {
        clear: both;
    }
    .form-group {
        margin-bottom: -15px;
        @media screen and (max-width: $size-xs-max){
            padding-right: 0;
            padding-left: 0;
        }
    }
    .group-checkbox {
        padding-bottom: 50px;
        margin-bottom: 50px;
        label.title-list {
            font-weight: 600;
            font-size: $font-size-26;
            color: $color-1;
            margin-bottom: 0;
            &.first {
                margin-right: 150px;
            }
            &.second {
                margin-right: 50px;
                width: 20%;
            }
            &.third {
                margin-right: 150px;
            }
        }
        &.border {
            border-bottom: 1px solid $color-1;
            padding-bottom: 100px;
            margin-bottom: 100px;
        }
        @media screen and (max-width: $size-sm-max){
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            label.title-list {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 20px;
                &.first {
                    margin-right: 0;
                }
                &.second {
                    margin-right: 0;
                    width: 100%;
                }
                &.third {
                    margin-right: 0;
                }
            }
            @media screen and (max-width: $size-xs-max){
                &.border {
                    padding-bottom: 50px;
                    margin-bottom: 50px;
                }
            }
        }
    }



    //// checkboxes and radiobuttons style ///////
    .list-checkBox  {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 100px;
        &.trois-columns {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 540px){
           grid-template-columns: 1fr; 
           &.trois-columns {
                grid-template-columns: 1fr;
            }
        }
    }

    .radio{
        display: block;
        cursor: pointer; 
        //width: 30%;
        user-select:none;
        text-align: left;
        &+.radio {
            margin-top: 12px;
        }
        input{
            display: none;
            &+span{
                display: inline-block; 
                position: relative;
                color: #03182F;
                padding-left: 30px;
                font-weight: 500;
                &:before{
                    content: '';
                    display: block; 
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    border-radius: 50%;
                    margin-right: 5px;
                    width: 19px;
                    height: 19px;
                    border: 1px solid #ccc;
                    background: #fff;
                }
                &:after {
                    content: '';
                    display: block; 
                    width: 13px;
                    height: 13px;
                    background: #0c4c92;
                    position: absolute;
                    border-radius: 50%;
                    top: 3px;
                    left: 3px;
                    opacity: 0;
                    transform: scale(0,0);
                    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
                }
                @media screen and (max-width: $size-xs-max){
                    font-size: 16px;
                }
            }
            &:checked+span:after{
                opacity: 1;
                transform: scale(1,1);
            }
        }
    }
/* END CHECKBOX STYLE */
//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
        @media screen and (max-width: $size-xs-max){
            height: 250px!important;
                &::placeholder {
                padding: 5px 5px !important;
            }
        }
    }
    .buttons {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 40px 0;
        @media screen and (max-width: 480px){
            .btn-filedoc {
                margin-top: 20px;
            }
        }
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
