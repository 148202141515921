.btn {
    background-color: $color-1;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 85px;

    font-weight: bold;
    font-size: $font-size-20;
    color: #FEF6EA;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transition: all 0.3s ease-in;
    
    &:hover {
        transition: all 0.3s ease-in;
        background-color: $color-3;
        color: #FEF6EA;
    }
    @media screen and (max-width: $size-xs-max) {
        width: 150px;
        height: 80px;
        font-size: 16px;
    }
}
