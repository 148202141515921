/*****************************************************/
// MENU
/* needs _sources/js/main.js to toggle var menu = ".menu-mobile"; */

nav.module-menu {
    position: relative;
    padding-top: $size-nav-height;
    @media screen and (max-width: $size-nav-toggle-breakpoint) {
        padding-top: 130px;
    }
    ul {
        list-style: none;
    }

    /****************************************************
    /* Set if the menu is over the slide show or not
    */
    // if menu do NOT overlap the content
    $menu-bottom-padding-desktop : $size-nav-height;
    $menu-bottom-padding-mobile : $size-nav-height-mobile;
    // if menu DO overlap over the content
    $menu-bottom-padding-desktop : 0;
    $menu-bottom-padding-mobile : 0;
    // sets the padding according to context
    padding-bottom: $menu-bottom-padding-desktop;
    @media screen and (max-width: $size-nav-toggle-breakpoint) {
        padding-bottom: $menu-bottom-padding-mobile;
    }

    /************ menu desktop ***************************/
    .menu-navbar {
        @extend .flex;
        position: fixed;
        top: 0;
        z-index: $z-index-menu;
        width: 100%;
        height: $size-nav-height;
        transition: all $transition-speed-fast ease;

        @extend .bg-color-nav; // see _colors.scss
        background-color:$color-white;
        /* purgecss start ignore */
        &.nav-invisible {
            top: -$size-nav-height;
            transition: all $transition-speed-fast ease;
        }
        &.nav-visible {
            top: 0;
            transition: all $transition-speed-fast ease;
        }

        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            height: 130px;
            transition: all $transition-speed-fast ease;
            &.nav-invisible {
                //top: -$size-nav-height-mobile;
                transition: all $transition-speed-fast ease;
            }
            &.nav-visible {
                top: 0;
                transition: all $transition-speed-fast ease;
            }  
        }

        /* purgecss end ignore */
        .main-menu-top {
            padding: 0 5%;
            width: 100%;
            background: $color-1;
            height: 40px;
            
            .section-onglet {
                height: 100%;
                & > ul {
                    @extend .flex;
                    @extend .flex-wrap;
                    @extend .justify-end; // pour menu a droite
                    @extend .items-center; // pour menu centré verticalement
                    @extend .p0;
                    @extend .m0;
                    height: 100%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        li.item {
                            display: none;
                        }
                    }
                    li:last-child > a {
                        @extend .pr0;
                        margin-right: 0;
                    }
                    li:first-child{
                        background-color: #C30000;
                    }

                    li {
                        height: 100%;
                        position: relative;
                        margin-left: 30px;
                        @media screen and (max-width: 1550px) {
                            margin-left: 20px;
                            @media screen and (max-width: $size-md-max) {
                                margin-left: 0;
                            }
                        }
                        @extend .flex;
                        @extend .items-center;
                        
                        &.icon {
                            a {
                                height: 100%;
                                @extend .flex;
                                @extend .items-center;
                            }
                            a .hoverable {
                                @extend .filter-normal;
                            }
                            a:hover .hoverable {
                                @extend .filter-hover;
                            }
                        }
                        &.lang {
                            a {
                                div {
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: #EBEBEB;
                                    letter-spacing: 0.56px;
                                    span {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                        & > a, & > .menu-title {
                            @extend .mx4;
                            @extend .text-color-nav;
                            @extend .font-nav-items;
                            transition: all $transition-speed-very-fast ease;
                        }
        
                        & > a, & > .menu-title {
                            @extend .mx4;
                            @extend .text-color-nav;
                            @extend .font-nav-items;
                            transition: all $transition-speed-very-fast ease;
                            @media screen and (max-width: 1325px) {
                               

                            }
                        }

                        & {
                            .arrow {
                                position: absolute;
                                left: 10px;
                                opacity: 0;
                                top: 0;
                                transition: .3s;
                            }
                            div.menu-title {
                                font-weight: bold;
                                font-size: 18px;
                                color: #FEF6EA;
                                letter-spacing: 1.8px;
                                transform: translateX(0);
                                transition: all $transition-speed-fast ease;  
                                @media screen and (max-width: 1550px) {
                                    font-size: 17px;
                                    @media screen and (max-width: 1415px) {
                                        font-size: 16px;
                                    }
                                }           
                            }
                            &:hover, &.active {
                                .arrow {
                                    opacity: 1;
                                    transition: .3s;
                                    left: 5px;
                                    top: 10px;
                                }
                                div.menu-title {
                                    transform: translateX(5px);          
                                }
                            }
                        }

                        &:hover {
                            transition: all $transition-speed-slow ease;
                            & > a, & > .menu-title {
                                @extend .text-color-nav-hover;
                            }
                        }

                        &.active, &:active, &:hover {
                            $menu-tab-width: 4px;
                        }

                        &.active, &:active {
                            & > div, & > .menu-title {
                                @extend .text-color-nav-active;
                            }
                        }         
                    }
                }
            }
        }
        .wrapper {
            &.left {
                padding-left: 5%;
                flex: 0 0 auto;
                height:100px;
                @media screen and (max-width: $size-nav-toggle-breakpoint) {
                    height: initial;
                }
                .logo {
                    height: 100%;
                    width: auto;
                    @extend .flex;
                    @extend .justify-center;
                    @extend .items-center;
                    @extend .pr6;
                    a {
                        @extend .text-color-nav-logo;
                        @extend .font-nav-logo;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    .slogan {
                        @extend .text-color-nav;
                        @extend .font-1-normal;
                        font-size: $font-size-12;
                        &:hover {
                            @extend .text-color-nav-hover;
                        }
                    }
                    @media screen and (max-width: 1335px) {
                        max-width: 250px;
                        @media screen and (max-width: $size-md-max) {
                            max-width: 230px;
                            margin-bottom: 15px;
                            @media screen and (max-width: 360px) {
                                max-width: 210px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            &.right {
                padding-right: 5%;
                flex: auto;
                @extend .flex;
                @extend .flex-column;
                @extend .justify-center;
                @extend .items-end;
                .main-menu {
                    //height: 70%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                    & > ul {
                        @extend .flex;
                        @extend .flex-wrap;
                        @extend .justify-end; // pour menu a droite
                        // @extend .justify-center; // pour menu au centre de l'espace
                        // @extend .justify-start; // pour menu a gauche
                        @extend .items-center; // pour menu centré verticalement
                        // @extend .items-start; // pour menu en haut
                        // @extend .items-end; // pour menu en bas
                        @extend .p0;
                        @extend .m0;
                        height: 100%;
                        li:last-child > a {
                            @extend .pr0;
                        }
                        li {
                            height: 80%;
                            @extend .flex;
                            @extend .items-center;
                            // @extend .items-start; // pour menu en haut
                            // @extend .items-end; // pour menu en bas
                            &.icon, &.phone, &.lang {
                                @extend .ml4;
                            }
                            &.icon {
                                a {
                                    height: 100%;
                                    @extend .flex;
                                    @extend .items-center;
                                }
                                a .hoverable {
                                    @extend .filter-normal;
                                }
                                a:hover .hoverable {
                                    @extend .filter-hover;
                                }
                            }

                            &.phone {
                                & > a, & > .menu-title {
                                    height: 100%;
                                    @extend .font-nav-phone;
                                    @extend .text-color-nav-phone;
                                }
                                &:hover > a, &:hover > .menu-title {
                                    @extend .text-color-nav-phone-hover;
                                }
                                a {
                                    height: 100%;
                                    .icon {
                                        height: 100%;
                                        @extend .mr2;
                                        @extend .flex;
                                        @extend .items-center;
                                        .hoverable {
                                            @extend .filter-normal;
                                        }

                                    }
                                }
                                &:hover > a {
                                    height: 100%;
                                    .icon {
                                        height: 100%;
                                        .hoverable {
                                            @extend .filter-hover;
                                        }
                                    }
                                }
                            }
                            &.lang {
                                & > a, & > .menu-title {
                                    @extend .font-nav-lang;
                                    @extend .text-color-nav-lang;
                                }
                                &:hover > a, &:hover > .menu-title {
                                    @extend .text-color-nav-lang-hover;
                                    transition: all $transition-speed-fast ease;
                                }
                            }
                        }
                    }
                }
                .main-menu {
                    & > ul {
                        & > li {
                            &:last-child a {
                                margin-right: 0;
                                //padding-right: 1rem;
                            }
                        }
                        li {
                            position: relative;
                            margin-left: 20px;
                            @media screen and (max-width: 1550px) {
                                margin-left: 10px;
                                @media screen and (max-width: 1415px) {
                                    margin-left: 5px
                                }
                            }

                            //transition: all $transition-speed-very-slow ease;
                            & > a, & > .menu-title {
                                @extend .mx4;
                                @extend .text-color-nav;
                                @extend .font-nav-items;
                                transition: all $transition-speed-very-fast ease;
                            }

                            &.item {
                                .arrow {
                                    position: absolute;
                                    left: 10px;
                                    opacity: 0;
                                    top: -3px;
                                    //width: 0;
                                    transition: .3s;
                                }
                                div.menu-title {
                                    font-weight: bold;
                                    font-size: 18px;
                                    letter-spacing: 1.8px;
                                    transform: translateX(0);
                                    transition: all $transition-speed-fast ease;
                                    @media screen and (max-width: 1550px) {
                                        font-size: 17px;
                                        @media screen and (max-width: 1415px) {
                                            font-size: 15px;
                                        }
                                    }   
                                }
                                &:hover , &.active {
                                    .arrow {
                                        opacity: 1;
                                        transition: .3s;
                                        //width: auto;
                                        left: 5px;
                                    }
                                }
                                &.dropdown:hover > a{
                                    .arrow {
                                        transform: rotate(90deg);
                                    }
                                }
                            }
                            
                            
                            &:hover, &.active  {

                                transition: all $transition-speed-slow ease;
                                
                                & > a {
                                    @extend .text-color-nav-hover;

                                    div.menu-title {
                                        transform: translateX(5px);
                                        transition: all $transition-speed-fast ease;
                                        color: $color-3;
                                    }
                                }
                            }

                         

                            &.active, &:active {
                                //background: $color-1;
                                & > div, & > .menu-title {
                                    @extend .text-color-nav-active;
                                }
                                
                            }

                            &.dropdown:hover + .submenu,
                            &.dropdown:hover > .submenu {
                                opacity: 100;
                                visibility: visible;
                            }

                            .submenu {
                                background-color: $color-white;
                                opacity: 0;
                                visibility: hidden;
                                @extend .flex;
                                @extend .justify-end;
                                position: absolute;
                                top: 18px;
                                left: 0;
                                display: block;
                                min-width: 100%;
                                padding: 20px 0 0 0;
                                margin: 0;
                                transition: opacity $transition-speed-very-fast ease-in;
                                z-index: $z-index-submenu;
                                @media screen and (max-width: $size-nav-shift-breakpoint) {
                                    //top: $size-nav-height-mobile / 2;
                                }
                                ul {
                                    @extend .flex;
                                    @extend .flex-column;
                                    @extend .justify-start;
                                    flex: 1 1 auto;
                                    margin-top: 0;
                                    @extend .p0;

                                    .subitem {
                                        padding: 5px 0;
                                        position: relative;
                                        white-space: nowrap;
                                        color: $color-black;
                                        margin-left:0;
                                        > a {
                                            display: flex;
                                            @extend .justify-between;
                                            @extend .items-center;
                                            min-height: 30px;
                                            width: 100%;
                                            color: $color-black;
                                            span {
                                            }

                                            i {
                                                top: -1px;
                                                font-size: 0.8rem;
                                            }
                                        }

                                        .arrow {
                                            position: absolute;
                                            left: 5px;
                                            opacity: 0;
                                            //width: 0;
                                            top: 9px;
                                            transition: .3s;
                                        }
                                        div.menu-title {
                                            transform: translateX(0);
                                            transition: all $transition-speed-fast ease;
                                                
                                        }
                                         &:hover {
                                        
                                            .arrow {
                                                opacity: 1;
                                                transition: .3s;
                                            }
                                             div.menu-title {
                                                transform: translateX(5px);
                                                transition: all $transition-speed-fast ease;
                                                    
                                            }
                                        }
                                        
                                        &:hover {
                                            //@extend .bg-color-nav-hover;
                                            border: none;

                                            div, span, i {
                                                @extend .text-color-nav-hover;
                                                color: $color-1;
                                            }

                                            & > .subitem-menu {
                                                display: block;
                                            }

                                        }

                                        &:active, &.active {
                                            //@extend .bg-color-nav-active;
                                            border: none;

                                            & > a, & > a > div {
                                                @extend .text-color-nav-active;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /************ menu toggle ***************************/
    .menu-toggle {
        display: none;
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            display: block;
        }
        cursor: pointer;
        //@extend .pr3;

        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }

        .bar-top {
             margin-bottom: 3px;
        }

        .bar-bottom {
             margin-top: 1px;
        }

        .word {
            // text-shadow: 0px 0px 6px white;
            @extend .font-1-normal;
            @extend .text-color-nav-toggle;
        }
    }

    /************ menu mobile ***************************/
    #menu-mobile {
        width: 400px;
        top: 0;
        right: 0;
        bottom: 0;
        
        z-index: $z-index-menu-mobile;
        // overflow: hidden;
        position: fixed;
        background: $color-white;
        transition: all $transition-speed-fast ease-in;
        transform: translate(100%, 0%);
        overflow-y: auto;
        &.toggled {
            overflow-y: auto;
            transform: translate(0, 0%);
        }
        @media screen and (max-width: 400px){
            width: 100%;
        }
        .menu-toggle {
            margin: 35px 0 0 0;
            border-radius: 10px;
            width: 175px;

            .word {
                background: $color-1;
                width: 200px;
                height: 60px;
                border-radius: 5px;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-28;
                    color: $color-white;
                }
            }
        }

        ul {
            margin: 0 auto;
            padding: 30px;

            li {
                // position: relative;
                //text-align: center;
                line-height: 100%;
                padding: 20px 0;
                border-bottom: 1px solid $color-black;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                a {
                    font-weight: 600;
                    font-size: 18px;
                    color: #1A1818;
                }

                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;

                    div.icons {
                        flex: 1 1;

                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }

                    a {
                        flex: 1 1;
                    }
                }

                & > a, & > .menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }

                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;

                    ul {
                        margin: 0;
                        padding: 0;

                        li.subitem {
                            border-bottom: none;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .menu-title {
                                margin-right: 20px;
                                font-weight: 700;

                            }
                            ul {
                                border-left: 1px $line-color-nav-mobile solid;
                                li {
                                    padding: 5px 20px;
                                }
                            }
                            a, a div {
                                font-size: 15px;
                                @extend .text-color-nav;
                                font-weight: 300;
                            }

                            &:hover {
                                > a, > a div {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active {
                                a, a div {
                                    @extend .text-color-nav-active;
                                }
                            }
                        }
                    }

                }

                &:hover {
                    > a, > a div {
                        @extend .text-color-nav-hover;
                    }
                }

                &.active, &.phone, &.lang {
                    > a, > a div {
                        color: $color-1;
                    }
                }
            }
        }
    }

}
